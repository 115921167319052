<template>
	<div class="lp">
		<h1 class="lp__header"><img src="@/assets/img/lp_newyearstamp/title.png" alt="気圧予報で体調管理 頭痛ーる for スゴ得"></h1>

		<!-- イントロ -->
		<section class="about-header__caption">
			<h2 class="lp__caption about-header__caption--text">「頭痛ーる」は天気の変化による<br>頭痛や体調不良を事前に予測できるサービスです。</h2>
		</section>
		<!-- /イントロ -->

		<!-- スタンプ -->
		<section class="lp-stamp">
			<h2 class="lp-stamp__title">頭痛ーるオリジナル<br>「お正月スタンプ」プレゼント！</h2>
			<p class="lp-stamp__intro">SNSやチャットでも使える、頭痛ーるキャラクターのスタンプをプレゼント！</p>
			<div class="lp-stamp__explanation">
				<p class="lp-stamp__explanation--text">下の画像をタップして、<br>長押しでダウンロードするニャ！</p>
				<img src="@/assets/img/lp_newyearstamp/maro.svg" alt="">
			</div>
			<div class="lp-stamp__download">
				<ul class="lp-stamp__download--list">
					<li class="lp-stamp__download--item"><img src="@/assets/img/stamp/newyear202011_stamp01.png" alt="NEW YEAR 2021"></li>
					<li class="lp-stamp__download--item"><img src="@/assets/img/stamp/newyear202011_stamp02.png" alt="招福"></li>
					<li class="lp-stamp__download--item"><img src="@/assets/img/stamp/newyear202011_stamp03.png" alt="2021 うっしっし"></li>
					<li class="lp-stamp__download--item"><img src="@/assets/img/stamp/newyear202011_stamp04.png" alt=""></li>
					<li class="lp-stamp__download--item"><img src="@/assets/img/stamp/newyear202011_stamp05.png" alt="お年玉"></li>
					<li class="lp-stamp__download--item"><img src="@/assets/img/stamp/newyear202011_stamp06.png" alt="賀 あけおめ"></li>
				</ul>
			</div>
		</section>
		<!-- /スタンプ -->

		<!-- スタンプの使い方 -->
		<section class="lp-howtouse">
			<h2 class="lp-howtouse__title">スタンプの使い方</h2>
			<div class="lp-howtouse__inner">
				<h3 class="lp-howtouse__subtitle">スタンプの保存方法</h3>
				<p class="lp-howtouse__text">欲しいスタンプ画像を長押しして、端末に保存してください。</p>
				<h3 class="lp-howtouse__subtitle">スタンプのご利用方法</h3>
				<p class="lp-howtouse__text">1．ご利用のメッセンジャーアプリで、「画像選択」ボタンから、ダウンロードした画像を写真一覧から選択します。<br>
				2．送信ボタンをタップするとトーク画面に投稿されます。</p>
			</div>
		</section>
		<!-- /スタンプの使い方 -->

		<!-- 頭痛タイプ診断 -->
		<div class="lp-headache-type-check__intro about-balloon">
			<div class="about-balloon__img is-type02"><i class="about-balloon__bg sprite sprite-about_balloon_img02" /></div>
			<div class="about-balloon__box">
				<div class="about-balloon__talk"><i class="about-balloon__bg sprite sprite-about_balloon_bg02" /></div>
				<p class="about-balloon__text is-type02">まずは自分の頭痛タイプを知るのが重要じゃ！</p>
			</div>
		</div>

		<section class="lp-headache-type-check headache-type-check__wrapper">
			<h2 class="headache-type-check__wrapper__title" v-show="!isShowHeadcheTypeCheck" @click="isShowHeadcheTypeCheck = true">
				<img src="@/assets/img/headache-type-check/zutool_300_250_20200310.png" alt="＼頭痛の症状をチェック！／あなたの頭痛タイプ診断">
			</h2>
			<transition name="fade" tag="section" @after-enter="onAfterEnter">
				<div class="headache-type-check__contents" v-show="isShowHeadcheTypeCheck">
					<headache-type-check />
				</div>
			</transition>
			<div class="about-rightnow__login">
				<p class="about-rightnow__login--text">痛みの傾向を記録してみよう！</p>
				<a @click="$router.push({ name: 'Cpsite', query: { id: sugotokuContentsId } })" class="about-rightnow__login--button">サイトTOPへ戻る</a>
			</div>
		</section>
		<!-- /頭痛タイプ診断 -->
	</div>
</template>

<script>
import cmnConst from '@/assets/js/constant.js'

// Compornents
import HeadacheTypeCheck from '@/components/Molecules/HeadacheTypeCheck'

export default {
	name: 'LpNewyearstamp',
	components: {
		HeadacheTypeCheck
	},
	data () {
		return {
			sugotokuContentsId: cmnConst.SUGOTOKU_CONTENTS_ID,
			isShowHeadcheTypeCheck: false
		}
	},
	computed: {
	},
	mounted () {
	},
	beforeDestroy () {
	},
	methods: {
		/**
		 * ページスクロール用の関数
		 */
		onScroll (id) {
			const elem = document.getElementById(id)
			const rect = elem.getBoundingClientRect()
			const elemTop = rect.top + window.pageYOffset

			window.scrollTo({
				top: elemTop,
				behavior: 'smooth'
			})
		},

		/**
		 * 頭痛タイプ診断の開閉処理
		 */
		onAfterEnter (elem) {
			const options = {
				container: 'body',
				duration: 500,
				easing: 'ease-out',
				offset: 0,
				cancelable: false,
				onStart: false,
				onDone: false,
				onCancel: false,
				x: false,
				y: true
			}
			this.$scrollTo(elem, options)

			// CHANGED: コンテンツの高さを確保する処理の見直しのためコメントアウト
			// this.contentHeight()
		},

		// コンテンツの高さをemitする処理（実処理は親で行う）
		// NOTE: 現在、未使用
		contentHeight () {
			this.$nextTick(() => {
				this.$emit('contentHeight')
			})
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../../assets/sass/variable";

// NOTE: LPページ限定の変数（デザインシステム外のため他での利用禁止）
$lp-text: #333;
$lp-stamp-explanation-background: #E9382A;
$lp-stamp-title-background: #C0A955;
$spacing-12: 0.75rem;
$spacing-30: 1.875rem;
$font-size-15: 0.937rem;
$spacing-38: 2.375rem;

.lp {
	overflow: hidden;
	background: $background-primary;
	color: $lp-text;

	&__header {
		width: 100%;
		margin: 0;
		line-height: 1;

		& > img {
			width: 100%;
			vertical-align: top;
		}
	}

	&__caption { font-size: $font-size-14; }

	&-stamp {
		font-size: $font-size-15;

		&__title {
			padding: $spacing-6 $spacing-10;
			margin: 0;
			text-align: center;
			font-size: $font-size-18;
			font-weight: bold;
			color: $text-seconday;
			background-color: $lp-stamp-title-background;
			background-image: url(~@/assets/img/lp_newyearstamp/stamp_title_background_right.svg), url(~@/assets/img/lp_newyearstamp/stamp_title_background_left.svg);
			background-position: left center, right center;
			background-repeat: no-repeat;
			background-size: auto 100%;
		}

		&__intro {
			padding: 0 $spacing-10;
			margin: $spacing-16 0 0 0;
		}

		&__explanation {
			display: flex;
			justify-content: flex-end;
			align-items: flex-end;
			padding: 0 $spacing-10;
			margin: $spacing-38 0 0 0;

			&--text {
				box-sizing: border-box;
				display: inline-block;
				position: relative;
				background: $lp-stamp-explanation-background;
				border-radius: 33px;
				width: 260px;
				margin: $spacing-10 0 0 0;
				padding: $spacing-12 $spacing-6 $spacing-12 $spacing-16;
				color: $text-seconday;

				&:before {
					content: '';
					position: absolute;
					border: 15px solid transparent;
					border-left: 36px solid $lp-stamp-explanation-background;
					right: -30px;
					bottom: 3px;
					margin-top: -15px;
					transform: rotate(20deg);
				}
			}

			& > img {
				display: inline-block;
				width: 81px;
				height: 78px;
				margin-left: $spacing-16;
			}
		}

		&__download {
			&--list {
				background: $stamp-download-background;
				display: flex;
				align-items: center;
				justify-content: space-around;
				flex-wrap: wrap;
				height: 66.93vw; // NOTE: mountedする際のコンテンツの高さを取得する際、画像の高さを含めた要素の高さ指定が必要(スタンプ95.5px*2 + 余白20px*3 = 251px)
				margin: $spacing-20 0 0 0;
				padding: $spacing-10 $spacing-4;
				box-sizing: border-box;
			}

			&--item {
				overflow: hidden;
				width: 30%;
				border-radius: 10px;

				& > img {
					width: 100%;
					height: auto;
					vertical-align: top;
					-webkit-touch-callout: default;
				}
			}
		}
	}

	&-howtouse {
		margin-top: $spacing-20;
		font-size: $font-size-15;

		&__title {
			font-size: $font-size-18;
			font-weight: bold;
			background: $stamp-howtouse-background;
			margin: 0;
			padding: $spacing-10;
		}

		&__inner { padding: $spacing-20 $spacing-10; }

		&__subtitle {
			font-size: $font-size-15;
			font-weight: bold;
			margin: 0;

			&:nth-of-type(2) { margin: $spacing-20 0 0 0; }
		}

		&__text { margin: 0; }
	}
}

.lp-headache-type-check__intro { margin: $spacing-20 auto 0; }

.headache-type-check__wrapper {
	width: 100%;
	margin: $spacing-20 0 0;

	&__title {
		margin: 0;
		text-align: center;

		img {
			width: 100%;
			max-width: 300px;
		}
	}
}

// NOTE: appDownloadページのスプライト画像を流用
.sprite {
	background-image: url(~@/assets/img/sprite_appdownload.png);
	background-repeat: no-repeat;
	display: block;

	&.sprite-about_balloon_bg02 {
		width: 580px;
		height: 159px;
		background-position: 0 -160px;
	}

	&.sprite-about_balloon_img02 {
		width: 122px;
		height: 146px;
		background-position: 0 -431px;
	}
}
</style>
