<template>
	<div class="headache-type-check">
		<div class="headache-type-check__header">
			<div class="headache-type-check__header--inner">
				<h3 class="headache-type-check__header--title"><span>頭痛の症状をチェック！</span>あなたの頭痛タイプ診断</h3>
				<img src="@/assets/img/headache-type-check/intro.svg" alt="マロとヒロシ" class="headache-type-check__header--img">
				<p>頭痛は種類によって症状や原因が異なることをご存知でしょうか。<br>
				まずは、自分のどの頭痛タイプに当てはまるのかチェックしてみましょう。</p>
			</div>
		</div>

		<div class="headache-type-check__supervisor">
			<h4 class="headache-type-check__supervisor--title">監修&ensp;舟久保&ensp;恵美&ensp;先生</h4>
		</div>

		<div class="headache-type-check__diagnosis">
			<ul class="headache-type-check__diagnosis--list">
				<li class="headache-type-check__diagnosis--item">
					<input type="checkbox" id="diagnosis01" @change="onChange('01', $event)">
					<label for="diagnosis01">こめかみから目の辺りで脈打つようなズキンズキンとした断続的な痛みがある</label>
				</li>
				<li class="headache-type-check__diagnosis--item">
					<input type="checkbox" id="diagnosis02" @change="onChange('01', $event)">
					<label for="diagnosis02">光や音に敏感となり、吐き気を伴う</label>
				</li>
				<li class="headache-type-check__diagnosis--item">
					<input type="checkbox" id="diagnosis03" @change="onChange('01', $event)">
					<label for="diagnosis03">週に1回～月に数回起きる</label>
				</li>
				<li class="headache-type-check__diagnosis--item">
					<input type="checkbox" id="diagnosis04" @change="onChange('01', $event)">
					<label for="diagnosis04">前兆としてキラキラした光、ギザギザの光が見える</label>
				</li>
				<li class="headache-type-check__diagnosis--item">
					<input type="checkbox" id="diagnosis05" @change="onChange('02', $event)">
					<label for="diagnosis05">後頭部を中心に頭全体が締め付けられるような鈍い痛みがある</label>
				</li>
				<li class="headache-type-check__diagnosis--item">
					<input type="checkbox" id="diagnosis06" @change="onChange('01', $event), onChange('02', $event)">
					<label for="diagnosis06">肩や首のコリ、目の疲れを伴う</label>
				</li>
				<li class="headache-type-check__diagnosis--item">
					<input type="checkbox" id="diagnosis07" @change="onChange('02', $event)">
					<label for="diagnosis07">体を動かしたり、マッサージすると良くなる</label>
				</li>
				<li class="headache-type-check__diagnosis--item">
					<input type="checkbox" id="diagnosis08" @change="onChange('02', $event)">
					<label for="diagnosis08">温めるとラクになる</label>
				</li>
				<li class="headache-type-check__diagnosis--item">
					<input type="checkbox" id="diagnosis09" @change="onChange('03', $event)">
					<label for="diagnosis09">目の奥がえぐられるような強烈な痛みがある。</label>
				</li>
				<li class="headache-type-check__diagnosis--item">
					<input type="checkbox" id="diagnosis10" @change="onChange('03', $event)">
					<label for="diagnosis10">目の充血や涙・鼻水等の症状もある</label>
				</li>
				<li class="headache-type-check__diagnosis--item">
					<input type="checkbox" id="diagnosis11" @change="onChange('03', $event)">
					<label for="diagnosis11">頭痛前に目のかすみや首の張りを感じる</label>
				</li>
				<li class="headache-type-check__diagnosis--item">
					<input type="checkbox" id="diagnosis12" @change="onChange('03', $event)">
					<label for="diagnosis12">就寝中でも痛みがある</label>
				</li>
			</ul>
		</div>

		<div class="headache-type-check__results">
			<h2 class="headache-type-check__results--title">下記の診断結果でもっとも大きい数値があなたのタイプです。タイプをクリックして詳しい診断内容を見てみましょう。</h2>
			<ul class="headache-type-check__results--list">
				<li class="headache-type-check__results--item"><p @click="onScroll('js_type01')"><span>{{ diagnosis['01'] }}</span>片頭痛</p></li>
				<li class="headache-type-check__results--item"><p @click="onScroll('js_type02')"><span>{{ diagnosis['02'] }}</span>緊張型頭痛</p></li>
				<li class="headache-type-check__results--item"><p @click="onScroll('js_type03')"><span>{{ diagnosis['03'] }}</span>群発頭痛</p></li>
			</ul>
			<p class="headache-type-check__results--caution">※数値が同数となった場合は両方のタイプをご覧になり、より当てはまる方を参考にしてください。片頭痛と緊張性型頭痛の数値が同数の場合は<span @click="onScroll('js_type04')">混合型頭痛</span>の可能性があります。</p>
		</div>

		<section class="headache-type-check__detail">
			<div class="headache-type-check__detail--transition" id="js_type01">
				<div class="headache-type-check__detail--inner">
					<h2 class="headache-type-check__detail--title">片頭痛タイプ</h2>
					<div class="headache-type-check__detail--contents">
						<img src="@/assets/img/headache-type-check/results_01.svg" alt="片頭痛タイプ" class="headache-type-check__detail--img">
						<p>片頭痛とはこめかみ部分の動脈や脳の血管が広がり、その周辺の神経が刺激されて起こる頭痛です。</p>
						<p>主に頭の片側にズキンズキンと脈打つように痛むのが特徴ですが、両側に痛みを感じる方も少なくありません。</p>
						<p>片頭痛特有の予兆として、「閃起暗点（せんきあんてん）」と呼ばれる、視野の中にキラキラした光、ギザギザの光などが見える症状があります。<br>
						その他には空腹感、あくび、イライラなどの症状、半身の脱力感やしびれや起こる場合もあります。<br>
						人によっては言葉が出にくくなる、言語症状が出ることもあります。</p>
					</div>
				</div>
			</div>

			<div class="headache-type-check__detail--transition" id="js_type02">
				<div class="headache-type-check__detail--inner">
					<h2 class="headache-type-check__detail--title">緊張型頭痛タイプ</h2>
					<div class="headache-type-check__detail--contents">
						<img src="@/assets/img/headache-type-check/results_02.svg" alt="緊張型頭痛タイプ" class="headache-type-check__detail--img">
						<p>緊張型頭痛は 痛みの継続時間が30分～7日間続いたり、頻度も月数回～15日以上と個人差が大きくなるのが特徴です。<br>
						片頭痛と大きく違い、締め付けられるような非拍動性の頭痛で、片側ではなく、両側で鈍い痛みを伴います。<br>
						吐き気を伴うこともなく、頭痛のために日常生活に支障が出ても痛みが増幅することなく、長期間寝込む事もありません。</p>
					</div>
				</div>
			</div>

			<div class="headache-type-check__detail--transition" id="js_type03">
				<div class="headache-type-check__detail--inner">
					<h2 class="headache-type-check__detail--title">群発頭痛タイプ</h2>
					<div class="headache-type-check__detail--contents">
						<img src="@/assets/img/headache-type-check/results_03.svg" alt="群発頭痛タイプ" class="headache-type-check__detail--img">
						<p>目の周り～前頭部、側頭部にかけての激しい頭痛が数週間から数カ月の期間に集中して起こることが特徴です。片側が痛くなるのは片頭痛と同じですが、目の奥がえぐられるようなほどの耐えがたい強い痛み、他にも目の充血や涙・鼻水等の症状があらわれる頭痛です。<br>
						また、期間もほぼ毎日のように同じ時間に起こります。多くの方は明け方の時間に痛みを伴い、20～30代の男性にも多く見られます。<br>
						頭痛中は落ち着かず、興奮したような状態になる方が多いです。</p>
					</div>
				</div>
			</div>

			<div class="headache-type-check__detail--transition" id="js_type04">
				<div class="headache-type-check__detail--inner">
					<h2 class="headache-type-check__detail--title">混合型頭痛タイプ</h2>
					<div class="headache-type-check__detail--contents">
						<img src="@/assets/img/headache-type-check/results_04.svg" alt="混合型頭痛タイプ" class="headache-type-check__detail--img">
						<p>頭が締め付けられるような痛み（緊張型頭痛の特徴）とたまにズキンズキンする強い痛みや吐き気がある（片頭痛の特徴）場合のどちらも感じられる場合があります。片頭痛と緊張型頭痛では対処法が異なるため、痛みが出やすい傾向を記録をとることによって慌てずに 対処できるでしょう。</p>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>

export default {
	name: 'HeadacheTypeCheck',
	components: {
	},
	data () {
		return {
			diagnosis: {
				'01': 0,
				'02': 0,
				'03': 0
			}
		}
	},
	computed: {
	},
	mounted () {
	},
	beforeDestroy () {
	},
	methods: {
		/**
		 * チェックリストの変更イベント用の関数
		 */
		onChange (key, e) {
			if (e.target.checked) {
				this.diagnosis[key] += 1
			} else {
				this.diagnosis[key] -= 1
			}
		},

		/**
		 * ページスクロール用の関数
		 */
		onScroll (id) {
			const elem = document.getElementById(id)
			const options = {
				container: 'body',
				duration: 500,
				easing: 'ease-out',
				offset: 0,
				cancelable: false,
				onStart: false,
				onDone: false,
				onCancel: false,
				x: false,
				y: true
			}
			this.$scrollTo(elem, options)
		}
	}
}
</script>

<style lang="scss">
// 変数定義SASS
@import "@/assets/sass/variable";

// 以下変数は流用のためデザインシステム外（他箇所での利用禁止）
$font-size-15: 0.9375rem;
$headche-type-check-title: #ff4e00;
$line-checkbox: #707070;
$background-detail: #fffcf2;
$spacing-5: 0.312rem;
$spacing-7: 0.437rem;
$spacing-12: 0.75rem;
$spacing-15: 0.937rem;
$spacing-18: 1.125rem;
$spacing-25: 1.562rem;
$spacing-30: 1.875rem;
$spacing-35: 2.187rem;

ul, ol, li {
    list-style-type: none;
}

.headache-type-check {
	width: 93.75%;
   margin: 0 auto;

	&__header {
		position: relative;
		background: none;
		border: $spacing-15 solid $primary;
		border-radius: 10px;
		max-width: 356px;
		width: 100%;
		height: auto;
		margin: 38px auto 0;
		padding: $spacing-25 0 15px;
		box-sizing: border-box;

		&--inner {
			padding: 0 8.2%;	// 余白はデザインシステム外

			p {
				margin: 0;
				font-size: $font-size-14;
				line-height: $line-height;
			}
		}

		&--title {
			color: $headche-type-check-title;
			font-size: $font-size-24;
			text-align: center;
			margin: 0;

			& > span {
				display: block;
				font-size: $font-size-14;
				margin-bottom: $spacing-5;
			}
		}

		&--img {
			display: block;
			width: 59%;
			height: auto;
			margin: $spacing-5 auto;
		}

		&:before {
			content: '';
			position: absolute;
			background: url('~@/assets/img/headache-type-check/intro_bg_sp.svg') no-repeat 50%;
			background-size: contain;
			width: 116px;
			height: 45px;
			top: -38px;
			left: calc(50% - 58px);
		}
	}

	&__supervisor {
		max-width: 356px;
		width: 100%;
		margin: 0 auto $spacing-20;
      padding: 0;

		&--title {
			padding-top: $spacing-4;
			margin: 0;
			text-align: right;
			font-weight: normal;
			font-size: $font-size-14;
		}
	}

	&__diagnosis {
		width: 100%;
		margin: $spacing-20 auto 0;

		&--list {
			padding: 0;
			margin: 0;
		}

		&--item {
			margin-top: $spacing-20;

			input {
				display: none;

				&:checked+label:before { opacity: 1 }
			}

			label {
				display: inline-block;
				position: relative;
				padding: 0 0 0 $spacing-35;
				line-height: $line-height;
				vertical-align: middle;
				cursor: pointer;

				&:before {
					content: '';
					display: block;
					position: absolute;
					border-right: 4px solid $primary;
					border-bottom: 4px solid $primary;
					top: 8px;
					left: 8px;
					width: 8px;
					height: 18px;
					margin-top: -$spacing-7;
					opacity: 0;
					transform: rotate(45deg);
				}

				&:after {
					content: '';
					display: block;
					position: absolute;
					border: 1px solid $line-checkbox;
					top: 0;
					left: 0;
					width: 25px;
					height: 25px
				}
			}

			&:first-child { margin-top: 0; }
		}
	}

	&__results {
		margin-top: $spacing-30;

		&--title {
			font-size: $font-size-16;
			line-height: $line-height;
			text-align: left;
		}

		&--list {
			display: flex;
			justify-content: space-between;
			width: 100%;
			margin: $spacing-10 auto 0;
			padding: 0;
		}

		&--item {
			background: $primary;
			width: 32%;
			height: auto;
			padding: $spacing-20 0 $spacing-10;
			text-align: center;
			border-radius: 10px;

			& > p {
				display: block;
				color: $text-seconday;
				font-size: $font-size-15;
				font-weight: 700;
				text-decoration: underline;

				& > span {
					display: block;
					position: relative;
					color: $_black;
					font-size: $font-size-16;
					font-weight: 700;
					background: $background-primary;
					width: 77px;
					height: 27px;
					margin: 0 auto $spacing-18;
					padding-top: $spacing-4;

					&:after {
						content: '';
						position: absolute;
						top: calc(100% - 1px);
						left: 50%;
						margin-left: -$spacing-7;
						border: 7px solid transparent;
						border-top: 11px solid $_white;
					}
				}
			}
		}

		&--caution {
			margin: $spacing-20 0 0;
			line-height: $line-height;

			& > span {
				color: $primary;
				font-weight: 700;
				text-decoration: underline;
			}
		}
	}

	&__detail {
		margin-top: $spacing-20;

		&--transition {
			margin-top: $spacing-20;
			box-sizing: border-box;

			&:first-child { margin-top: 0; }
		}

		&--inner {
			border: 4px solid $primary;
			border-radius: 18px;
			background: $background-detail;
			padding: 0 $spacing-10 $spacing-20;

			p {
				margin-top: $spacing-20;
				line-height: $line-height;

				&:first-child { margin-top: 0; }
			}
		}

		&--title {
			color: $primary;
			font-size: $font-size-18;
			border-bottom: 2px solid $primary;
			padding: $spacing-18 0 $spacing-12;
			margin: 0;
			text-align: center;
		}

		&--img {
			width: 63%;
			height: auto;
			display: block;
			margin: $spacing-20 auto;
		}
	}
}
</style>
